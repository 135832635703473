<template>
  <Modal :title="t$.title" maxWidth="454" icon="$symbols-alert-outline">
    <p class="text-paragraph-2" v-text="t$.description"/>
    <p class="mt-4 text-paragraph-2" v-text="t$.confirmationText"/>
    <template #append>
      <Button color="primary" :label="t$.actions.cancel" :ga="gaEvents.cancel" text @click="$emit('cancel')"/>
      <Button :loading="loading" color="primary" :ga="gaEvents.remove" :label="t$.actions.confirmation" @click="$emit('delete')"/>
    </template>
  </Modal>
</template>

<script>
import { Button, Modal, useLocale } from '@locaweb/design-system-vue'
import ga from '@ga'

export default {
  name: 'DeleteModal',
  components: {
    Modal,
    Button
  },
  props: {
    loading: Boolean,
    record: {
      type: Object,
      required: true
    }
  },
  setup (props) {
    const t$ = useLocale('records.list.deleteModal')
    const gaEvents = {
      cancel: {
        ...ga.record.modal.cancel,
        category: ga.record.modal.cancel.category.replaceValues({ type: props.record.type })
      },
      remove: {
        ...ga.record.modal.remove,
        category: ga.record.modal.remove.category.replaceValues({ type: props.record.type })
      }
    }
    return { t$, gaEvents }
  }
}
</script>
