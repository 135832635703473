<template>
  <LoadingArea :displayContent="!loading">
    <template #loading>
      <slot name="loading">
        <Row>
          <Col cols="12" v-if="table">
            <SkeletonLoader  type="table-tbody" elevation="1"/>
          </Col>
          <Col v-else v-for="i in 4" :key="i" cols="12" sm="6">
            <SkeletonLoader type="list-item-avatar,divider,list-item-two-line" elevation="1"/>
          </Col>
        </Row>
      </slot>
    </template>
    <slot v-bind="{data, loading, error, serverError }"/>
    <slot name="error" v-if="error">
      <PageResult :error="true" :text-results="resultText" :action-route="createLink"/>
    </slot>
    <slot v-else-if="isEmpty" name="empty">
      <PageResult :text-results="resultText" :action-route="createLink" :external-action="externalAction" />
    </slot>
    <template v-else-if="table">
      <Card contentPaddingLess>
        <template #header>
          <slot name="header">
          </slot>
        </template>
        <template #headerActions>
          <slot name="headerActions"></slot>
        </template>
        <Table v-bind="tableProps" :options.sync="pagination" @update:page="execute" @update:items-per-page="execute"
        @update:options="serverSortItems">
          <template v-for="tableHeader in tableSlots" v-slot:[`item.${tableHeader.value}`]="{ item }">
            <slot :name="`item.${tableHeader.value}`" v-bind="{item}"/>
          </template>
          <template #actions="{ item }">
            <slot name="actions" v-bind="{item}"></slot>
          </template>
        </Table>
      </Card>
    </template>
  </LoadingArea>
</template>

<script>
import PageResult from '@/components/PageResult'
import { Card, Col, LoadingArea, Row, SkeletonLoader, Table, useAPI } from '@locaweb/design-system-vue'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import { computed, getCurrentInstance, onBeforeMount, watch, ref } from 'vue'

export default {
  name: 'RequestComponent',
  components: { LoadingArea, Row, Col, SkeletonLoader, Table, Card, PageResult },
  props: {
    requestFunction: {
      type: Function,
      required: true
    },
    responseKey: {
      type: String,
      required: true
    },
    params: {
      type: [Object, Array]
    },
    list: {
      type: Boolean,
      default: true
    },
    table: Boolean,
    dataProcessingFuction: Function,
    tableHeader: {
      type: Array,
      default: () => []
    },
    hasEmptyState: {
      type: Boolean,
      default: true
    },
    resultText: Object,
    createLink: String,
    filter: {
      type: Array
    },
    filterFunction: Function,
    noDataText: String,
    externalAction: Boolean,
    serverPagination: Boolean,
    serverSearch: String,
    isAdmin: Boolean
  },
  emits: ['finish'],
  setup (props, { emit, expose }) {
    if (typeof Object.prototype.getValue !== 'function') {
      // eslint-disable-next-line no-extend-native
      Object.defineProperty(Object.prototype, 'getValue', {
        value: function (path) {
          return get(path, this)
        },
        writable: true
      })
    }
    const { proxy: { $scopedSlots } } = getCurrentInstance()
    // Realiza a request a api
    const pagination = ref({
      page: 1,
      itemsPerPage: 5
    })

    const search = ref(props.serverSearch)

    const sortBy = ref(null)

    const { data, execute, loading, error, serverError, responseHeaders } = useAPI({
      request: props.requestFunction,
      param: {
        params: props.params,
        ...props.serverPagination && { pagination },
        search,
        sortBy,
        isAdmin: props.isAdmin
      },
      key: props.responseKey,
      dataProcessingFuction: props.dataProcessingFuction
    })
    onBeforeMount(() => {
      props.responseKey && execute()
    })

    watch(() => data.value, () => {
      if (data.value) {
        pagination.value.total = parseInt(responseHeaders?.value['total-count']) || 0
        emit('finish', { data: data.value, headers: responseHeaders.value })
      }
    })

    watch(() => props.serverSearch, (nextValue) => {
      search.value = nextValue
      execute()
    })

    const filteredData = computed(() => {
      return props.filterFunction && data.value ? props.filterFunction(data) : data.value
    })

    const isEmpty = computed(() => (props.hasEmptyState && ((!data.value && !props.serverSearch) ||
      (data.value?.isEmpty() && !props.serverSearch))) || (props.hasEmptyState && props.isAdmin && data.value?.isEmpty()))

    expose({
      reload: () => {
        execute()
        emit('finish', { data: data.value, headers: responseHeaders?.value })
      }
    })

    function sortItems (items, sortBy, sortDesc) {
      let sortedValues = items
      const compareString = (firstValue, secondValue, sortDesc) => {
        if (firstValue < secondValue) { return sortDesc ? 1 : -1 }
        if (firstValue > secondValue) { return sortDesc ? -1 : 1 }
        return 0
      }
      sortBy.forEach((key, index) => {
        sortedValues = sortedValues.sort((a, b) => {
          /* istanbul ignore next */
          const firstValue = sortBy[index].getValue(a)
          /* istanbul ignore next */
          const secondValue = sortBy[index].getValue(b)
          if (typeof firstValue === 'object') {
            return 0
          }
          if (Number(firstValue)) {
            return sortDesc[index] ? firstValue - secondValue : secondValue - firstValue
          } else {
            return compareString(firstValue, secondValue, sortDesc[index])
          }
        })
      })
      return sortedValues
    }

    function serverSortItems (options) {
      if (!options.sortBy.isEmpty()) {
        sortBy.value = {
          ...options.sortBy.reduce((acc, it, index) => ({ [it]: options.sortDesc[index] ? 'desc' : 'asc' }), {})
        }
      } else {
        sortBy.value = null
      }
    }

    watch(() => sortBy.value, (nextValue, oldValue) => {
      if ((nextValue && !isEqual(nextValue, oldValue)) || nextValue === null) {
        execute()
      }
    })

    const tableProps = computed(_ => {
      return {
        itemKey: 'domain',
        headers: props.tableHeader,
        items: filteredData.value || [],
        noDataText: props.noDataText,
        customSort: sortItems,
        itemsPerPageOptions: [5, 15, 30],
        page: pagination.value.page,
        ...props.serverPagination && { serverItemsLength: pagination.value.total || 0 }
      }
    })

    const tableSlots = computed(() => props.tableHeader.filter(th => !!$scopedSlots[`item.${th.value}`]))
    return {
      tableProps,
      data: filteredData,
      loading,
      error,
      serverError,
      isEmpty,
      tableSlots,
      responseHeaders,
      pagination,
      execute,
      serverSortItems
    }
  }
}
</script>
