import { defineStore } from 'pinia'

// Exemplo de configuração básica do Pinia, biblioteca de gerenciamento de estado que o VueJs dá suporte
export const authStore = defineStore('auth', {
  state: () => {
    return {
      logged: false,
      user: {
        name: 'lorem Ipsum',
        email: 'lorem@gmail.com',
        plan: 'Plano Basico',
        isAdmin: false
      }
    }
  },
  getters: {
    userName (state) {
      return state.user.name
    },
    userInfo (state) {
      return {
        name: state.user.name,
        additionalInfo: state.user.plan
      }
    },
    isAdmin (state) {
      return !!state.user?.authorities?.some(auth => ['DNS-API-ADMIN', 'DNS-SERVICES_ADMIN', 'DNS-PANEL_DNS-SERVICES_READ_ONLY'].includes(auth))
    }
  },
  actions: {
    login (user) {
      this.logged = true
      this.user = user
    }
  }
})
