import { serverError } from '@/locales/pt-br/errors'
import whoIs from './whoIs'

export default {
  subtitle: 'Aqui você pode configurar os serviços ligados aos seus domínios.',
  records: {
    title: 'Entradas de DNS'
  },
  createAction: 'Adicionar Entrada',
  whoIs,
  table: {
    header: {
      name: 'Entrada',
      type: 'Tipo',
      priority: 'Prioridade',
      content: 'Conteúdo',
      actions: 'Ações'
    },
    emptyText: 'Nenhum domínio para ser exibido',
    actions: {
      more: 'Mais Opções',
      edit: 'Visualizar entrada',
      delete: 'Excluir entrada',
      blocked: 'Não é possível alterar este tipo de entrada'
    }
  },
  result: {
    error: { ...serverError, action: 'Listar Zonas' },
    empty: {
      title: 'Dominio sem entradas... sou eu assim sem você..',
      description: 'As entradas são as principais configurações para o DNS funcionar no dominio e ainda não encontramos entradas para o dominio {domain}. Você pode começar a adiciona-las utilizando o botão abaixo.',
      action: 'Adicionar Entradas'
    },
    notFound: {
      title: 'Não foi possível encontrar a Zona de DNS.',
      description: 'O sistema não encontra a Zona de DNS {domain} vinculada ao seu domínio.',
      action: 'Listar Zonas'
    }
  },
  deleteModal: {
    title: 'Excluir entrada de DNS',
    description: 'Ao excluir essa entrada, todos os serviços relacionados a ela vão parar de funcionar. Certifique-se dos impactos ao realizar esta ação.',
    confirmationText: 'Deseja realmente excluir essa entrada?',
    actions: {
      cancel: 'Talvez mais tarde',
      confirmation: 'Excluir entrada'
    }
  },
  alerts: {
    delete: {
      success: 'Entrada DNS excluída com sucesso',
      error: 'Erro ao tentar excluir a entrada de DNS, tente novamente em breve.'
    }
  }
}
