const videos = {
  manage: 'https://www.youtube.com/watch?v=vdEJG6yxySE',
  change: 'https://www.youtube.com/watch?v=115c8BwOXeU',
  entries: 'https://www.youtube.com/watch?v=85UfPCsAjaU'
}

export default {
  title: 'Dúvidas frequentes',
  buttonLabel: 'Dúvidas frequentes',
  items: [
    {
      label: 'O que são servidores, provedores e zona de DNS?',
      content: `
        Os <strong>servidores DNS</strong> são responsáveis por fazer com que o seu domínio possa ser localizado na internet. Normalmente, os endereços dos servidores são iniciados em NS, como no exemplo: ns1.locaweb.com.br.<br><br>
        
        Um <strong>provedor de serviços</strong> é uma empresa previamente homologada e certificada através de um contrato para exercer o registro e a manutenção dos domínios e titulares (entidades) na sua própria plataforma. Em outras palavras, a empresa é autorizada a vender os domínios que estiverem disponíveis.<br><br>

        A <strong>zona de DNS</strong> é um catálogo de endereços e informações sobre o seu domínio. Neste espaço você encontrará os caminhos necessários para que os subdomínios, e-mails e outros serviços funcionem. É importante saber que o responsável pela sua zona de DNS é o seu <strong>provedor de serviços</strong>.
      `,
      linkText: 'Gerenciando o DNS do seu domínio',
      linkUrl: videos.manage,
      type: 'video'
    },
    {
      label: 'O que são nameservers?',
      content: `
        Os <strong><em>nameservers</em></strong>, também conhecidos como <strong>servidores DNS</strong>, são responsáveis por gerenciar as solicitações da internet para o seu domínio. É possível utilizar os <em>nameservers</em> da Locaweb ou personalizá-los para apontar para outros provedores de serviços.<br><br>

        Caso você hospede seu site e/ou suas contas de e-mail na Locaweb, recomendamos que não altere os <em>nameservers</em> que já vieram configurados.<br><br>

        Se quiser conhecer mais detalhes sobre <em>nameservers</em>, recomendamos que você assista o vídeo indicado logo a seguir.
      `,
      linkText: 'Como alterar o servidor DNS do seu domínio?',
      linkUrl: videos.change,
      type: 'video'
    },
    {
      label: 'O que são as entradas de DNS na Zona de DNS?',
      content: `
        As entradas de DNS são registros de configuração que determinam como vai ser o comportamento do seu domínio.<br><br>

        Em resumo, são as informações das entradas de DNS que determinam em que servidor está ou será hospedado o seu site, e-mail, subdomínio, entre outros.<br><br>

        Cada entrada possui um tipo que varia conforme a necessidade, por exemplo: entrada do <strong>tipo A</strong> indica o IP em que seu site está; entradas do <strong>tipo MX</strong> identificam o servidor de e-mails que vai funcionar no domínio.<br><br>

        Se quiser conhecer mais detalhes sobre como alterar suas entradas DNS, recomendamos que você assista o vídeo indicado logo a seguir.
      `,
      linkText: 'Como criar as entradas na zona de DNS',
      linkUrl: videos.entries,
      type: 'video'
    },
    {
      label: 'Não consigo adicionar entradas com “@” ou deixá-las em branco.',
      content: `
        Caso precise adicionar o caractere "@” ou deixar o campo de entrada em branco, substitua-os pelo caractere de ponto final (.) na hora de preencher.
      `,
      type: 'text only'
    },
    {
      label: 'Adicionei as entradas DNS, mas elas não funcionaram.',
      content: `
        Existem muitos motivos que podem acontecer para as entradas DNS não serem propagadas pela internet. A seguir, você encontrará as razões mais comuns:<br><br>

        <strong>Domínio congelado</strong><br>
        Confira se o seu domínio está com o status de “ativo”, pois, caso contrário, ele não será propagado pela internet.<br><br>

        <strong>Servidor DNS externo (<em>nameserver</em>)</strong><br>
        Acesse a página "Servidores e Zona DNS" do seu domínio e confira se o seu servidor de DNS é a Locaweb. Caso não seja, as suas entradas não funcionarão, ou só poderão funcionar quando adicioná-las ao servidor de DNS cadastrado.<br><br>

        Lembre-se que os servidores de DNS são endereços começados com NS (ex.: <em>ns1.locaweb.com.br</em>).<br><br>

        <strong>Tempo de propagação</strong><br>
        O tempo de propagação pode levar desde algumas horas até 48 horas para acontecer, e fazer com que qualquer alteração seja propagada por toda internet.<br><br>

        <strong>Validação ICANN</strong><br>
        Caso seu domínio seja internacional, a validação de e-mail pela ICANN acontece logo após a contratação do registro de domínio internacional. Essa validação por e-mail é obrigatória por exigência da entidade. Confira sua caixa de entrada e, caso o e-mail não tenha chegado, entre em contato com nosso time de suporte.<br><br>

        Caso você esteja passando por uma situação diferente das que foram citadas acima, recomendamos que entre em contato com o nosso time de suporte.
      `,
      type: 'text only'
    },
    {
      label: 'Qual é o tempo de propagação das alterações feitas no DNS?',
      content: `
        O tempo de propagação pode levar desde algumas horas e até mesmo chegar a 48 horas para acontecer, e fazer com que qualquer alteração seja propagada por toda internet.<br><br>

        Para saber mais sobre este assunto, acesso o nosso artigo indicado abaixo.
      `,
      linkText: 'Propagação de DNS na internet',
      linkUrl: 'https://www.locaweb.com.br/ajuda/wiki/qual-seria-o-tempo-de-propagacao-do-meu-site-faq-locaweb/',
      type: 'article'
    }
  ]
}
