import DesignSystem from '@locaweb/design-system-vue'
import '@locaweb/design-system-vue/dist/locastyle.css'
import locales from '@/locales'
import { makeServer } from '@/server'
import routes from '@/router'
import IconsPlugin from '@locaweb/design-system-icons'
import { PiniaVuePlugin } from 'pinia'
import { Serializer } from 'jsonapi-serializer'
import * as url from '@/config/url'

const apiUrl = url.apiUrl()

export {
  apiUrl
}

export default {
  install (vue) {
    window.serializer = Serializer
    // Configuração do Plugin do Pinea
    vue.use(PiniaVuePlugin)
    // Configuração do Design System
    vue.use(DesignSystem, {
      locales, // configuração do locales que pode ser acessado em cada componente por $lc
      env: process.env.NODE_ENV,
      ...process.env.VUE_APP_SENTRY_DSN && { sentry: { dsn: process.env.VUE_APP_SENTRY_DSN } },
      router: { routes }, // Configuração das rotas
      http: { // Configuração de um cliente http para a baseURL definida e com interceptador para por o cookie informado na request, caso ele exista
        baseURL: apiUrl,
        AuthorizationCookieName: process.env.VUE_APP_COOKIE_NAME
      },
      ...process.env.VUE_APP_GA_TOKEN && {
        ga: {
          config: {
            id: process.env.VUE_APP_GA4_TOKEN
          },
          includes: [
            { id: process.env.VUE_APP_GA_TOKEN }
          ],
          appName: process.env.VUE_APP_GA4_NAME
        }
      },
      ...process.env.VUE_APP_GTM_TOKEN && {
        gtm: {
          id: process.env.VUE_APP_GTM_TOKEN
        }
      }
    })
    vue.use(IconsPlugin) // Configuração do plugin de icone do ecossistema do DS
    // Inicialização do mock da api, caso não precise do mock pode ser removido assim como o diretório src/server
    if (process.env.VUE_APP_ENABLE_MIRAGE === 'true') {
      makeServer()
    }
  }
}
