export default Object.freeze({
  A: 'A',
  AAAA: 'AAAA',
  CNAME: 'CNAME',
  MX: 'MX',
  NS: 'NS',
  TXT: 'TXT',
  SOA: 'SOA',
  SRV: 'SRV',
  PTR: 'PTR'
})
