import { readonly, ref } from 'vue'

export function useSelectItem () {
  const selected = ref(null)

  const setItem = (item) => {
    selected.value = item
  }
  const clearItem = () => setItem(null)
  return [readonly(selected), setItem, clearItem]
}
