import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import DesignSystem from '@locaweb/design-system-vue'
import Config from './config'
import { createPinia } from 'pinia'

// Configuração de todas as dependências da aplicação
Vue.use(Config)

new Vue({
  pinia: createPinia(), // inicialização do Pinia
  ...DesignSystem, // Configuração do DS para funcionar os componentes, rotas, GA
  render: h => h(App)
}).$mount('#app')
