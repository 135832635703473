export default {
  search: {
    trigger: 'input',
    action: 'enter_input',
    category: '[Zona de DNS] Lista de Domínios',
    label: 'Pesquisar Domínio'
  },
  manage: {
    action: 'click_button',
    category: '[Zona de DNS] Lista de Domínios',
    label: 'Administrar'
  },
  createRecord: {
    action: 'click_button',
    category: '[Zona de DNS] Lista de entradas DNS',
    label: 'Adicionar entrada'
  }
}
