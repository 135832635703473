const DNSPanelCategory = '[Painel de DNS] {page}'

const questionClickEvent = (label) => {
  return {
    action: 'click',
    category: DNSPanelCategory,
    label
  }
}

const linkClickEvent = (label) => {
  return {
    action: 'click_link',
    category: DNSPanelCategory,
    label
  }
}

export default {
  openFAQ: {
    action: 'click_button',
    category: DNSPanelCategory,
    label: 'Dúvidas frequentes'
  },
  questionClickEvent,
  linkClickEvent
}
