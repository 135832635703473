import {
  Card,
  useStorage,
  useVueRouter
} from '@locaweb/design-system-vue'
import { watch, ref, computed, getCurrentInstance } from 'vue'
import ga from '@ga'
import { useMapRecord } from '@/views/record/form/functions/useMapRecord'
import { ZonesRoutesPath } from '@/views/zone/router'
import { model } from '@/views/record/api/model/Record'
import RecordTypeEnum from '@/enums/RecordTypeEnum'

export function useMountPage (props) {
  const { proxy: { $breakpoints, $dga } } = getCurrentInstance()
  const { $router, $route } = useVueRouter()
  const [sessionRecord] = useStorage({ key: 'record', initialValue: null, type: 'sessionStorage' })

  const isEdit = $route.name === 'recordEdit'

  const record = isEdit ? ref(sessionRecord.value?.clone()) : ref(model.clone())

  const action = isEdit ? 'Editar' : 'Criar'

  watch(() => record.value.type, (type) => {
    !isEdit && $dga.event({
      ...ga.record.form.typeChange,
      category: ga.record.form.typeChange.category.replaceValues({ type, action }),
      label: ga.record.form.typeChange.label.replaceValues({ type, action })
    })
  })

  const gaEvents = computed(_ => ({
    addContent: {
      ...ga.record.form.addContent,
      category: ga.record.form.addContent.category.replaceValues({
        type: record.value.type,
        action
      })
    },
    removeContent: {
      ...ga.record.form.removeContent,
      category: ga.record.form.removeContent.category.replaceValues({
        type: record.value.type,
        action
      })
    },
    entryName: {
      ...ga.record.form.entryName,
      category: ga.record.form.entryName.category.replaceValues({
        type: record.value.type,
        action
      })
    },
    content: {
      ...ga.record.form.entryName,
      category: ga.record.form.entryName.category.replaceValues({
        type: record.value.type,
        action
      })
    },
    action: {
      ...ga.record.form.action,
      category: ga.record.form.action.category.replaceValues({
        type: record.value.type,
        action
      }),
      label: ga.record.form.action.category.replaceValues({
        action
      })
    }
  }))

  if (isEdit && !record.value) {
    record.value = model.clone()
    $router.push(ZonesRoutesPath.DETALHAR.path.replace(':domain', props.domain))
  } else {
    useMapRecord(record, isEdit)
  }

  const hasPriority = computed(_ => [RecordTypeEnum.SRV, RecordTypeEnum.MX].includes(record.value.type))
  const contentWrapperProps = computed(_ => ({
    outlined: true,
    is: hasPriority.value && $breakpoints.mobile ? Card : 'li',
    ...hasPriority.value && $breakpoints.mobile && { class: 'pb-2 mb-2', tag: 'li' }
  }))

  return { record, gaEvents, contentWrapperProps, isEdit }
}
