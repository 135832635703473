<template>
  <Row>
    <Col cols="6" md="2" v-if="isSRV">
      <TextField label="Peso" type="number" required v-model.number="record.content[index].weight" v-on="bindEvents('weight')"
      :ga="gaEvents.weight"/>
    </Col>
    <Col cols="6" md="2" v-if="isSRV">
      <TextField label="Porta" type="number" required v-model.number="record.content[index].port" :ga="gaEvents.port"
                 v-on="bindEvents('port')"/>
    </Col>
    <Col cols="12" :md="contentProps.column">
      <TextField :label="contentProps.label" :errorMessages="contentErrorMessage[index]" required :ga="gaEvents.content"
                 v-on="bindEvents('value')" v-model="record.content[index].value" :maxLength="6000" :lowercase="hasDomainContent"/>
    </Col>
    <Col cols="6" md="2" v-if="hasPriority">
      <TextField label="Prioridade" type="number" :maxLength="9999" required v-on="bindEvents('priority')"
                 v-model.number="record.content[index].priority" :ga="gaEvents.priority"/>
    </Col>
    <Col cols="3" v-if="displayRemoveButton(index)">
      <Button class="mt-7" text large color="primary" @click="$emit('remove',index)" prependIcon="$objects-trash-outline"
      :label="t$.actions.removeContent" :ga="gaEvents.removeContent"/>
    </Col>
  </Row>
</template>

<script>
import { Button, Col, Row, TextField, useLocale } from '@locaweb/design-system-vue'
import { computed, getCurrentInstance, ref } from 'vue'
import RecordTypeEnum from '@/enums/RecordTypeEnum'
import { validationMessages } from '@/views/record/api/model/Record'
import ga from '@ga'

export default {
  name: 'ContentForm',
  components: {
    Row,
    Col,
    TextField,
    Button
  },
  props: {
    index: {
      type: Number,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    $v: {
      type: Object,
      required: true
    },
    model: {
      type: Object,
      required: true
    },
    isEdit: Boolean
  },
  setup (props) {
    const t$ = useLocale('records.form')
    const record = ref(props.model)
    const { proxy: { $breakpoints } } = getCurrentInstance()
    const contentProps = computed(_ => ({
      column: isSRV.value ? 3 : 5,
      label: isSRV.value
        ? t$.form.content.objectLabel
        : t$.form.content.label
    }))
    const isSRV = computed(_ => props.type === RecordTypeEnum.SRV)
    const hasDomainContent = computed(_ => [RecordTypeEnum.CNAME, RecordTypeEnum.MX, RecordTypeEnum.NS].includes(props.type))
    const hasPriority = computed(_ => [RecordTypeEnum.SRV, RecordTypeEnum.MX].includes(props.type))

    function displayRemoveButton (index) {
      return index >= 1 && !$breakpoints.mobile
    }

    function bindEvents (field) {
      return {
        input: () => props.$v.record?.content.$each[props.index][field].$touch(),
        blur: () => props.$v.record?.content.$each[props.index][field].$touch()
      }
    }

    const gaEvents = computed(_ => ({
      content: {
        ...ga.record.form.content,
        category: ga.record.form.content.category.replaceValues({
          type: record.value.type,
          action: props.isEdit ? 'Editar' : 'Criar',
          label: record.value.type === RecordTypeEnum.SRV ? 'Objeto' : 'Conteúdo'
        })
      },
      removeContent: {
        ...ga.record.form.removeContent,
        category: ga.record.form.removeContent.category.replaceValues({
          type: record.value.type,
          action: props.isEdit ? 'Editar' : 'Criar'
        })
      },
      priority: {
        ...ga.record.form.priority,
        category: ga.record.form.priority.category.replaceValues({
          type: record.value.type,
          action: props.isEdit ? 'Editar' : 'Criar'
        })
      },
      weight: {
        ...ga.record.form.weight,
        category: ga.record.form.weight.category.replaceValues({
          type: record.value.type,
          action: props.isEdit ? 'Editar' : 'Criar'
        })
      },
      port: {
        ...ga.record.form.port,
        category: ga.record.form.port.category.replaceValues({
          type: record.value.type,
          action: props.isEdit ? 'Editar' : 'Criar'
        })
      }
    }))

    return {
      t$,
      contentProps,
      isSRV,
      hasDomainContent,
      hasPriority,
      displayRemoveButton,
      record,
      bindEvents,
      gaEvents
    }
  },
  computed: {
    ...validationMessages
  }
}
</script>
