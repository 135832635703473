export default {
  createTitle: 'Entradas DNS',
  editTitle: 'Editar entrada de DNS',
  subtitle: 'As entradas são o registro de configuração que determinam o comportamento do serviço do domínio',
  recordTypes: {
    A: {
      title: 'Entradas tipo A',
      description: 'As entradas do tipo A normalmente são utilizadas para apontar endereços de IP no formato IPV4. Utilizados para fazer a ponte entre o site e o dominio registrado.'
    },
    AAAA: {
      title: 'Entradas tipo AAAA',
      description: 'As entradas do tipo AAAA normalmente são utilizadas para apontar endereços de IP no formato IPV6. Utilizados para fazer a ponte entre o site e o dominio registrado.'
    },
    CNAME: {
      title: 'Entradas tipo CNAME',
      description: 'Os registros CNAME normalmente são usados para mapear um subdomínio como www ou mail ao domínio que hospeda o conteúdo desse subdomínio.'
    },
    MX: {
      title: 'Entradas tipo MX',
      description: 'As entradas MX são utilizadas para serviços de email, elas definem o provedor responsável por enviar e receber mensagens de e-mail para um domínio.'
    },
    NS: {
      title: 'Entradas tipo NS',
      description: 'As entradas NS indica os servidores DNS que respondem pelo domínio ou subdomínio. O ideal é definir pelo menos dois registros NS, geralmente um principal e outro secundário.'
    },
    TXT: {
      title: 'Entradas tipo TXT',
      description: 'É uma entrada que possibilita a inserção de um texto curto em um hostname, a utilização dessa entrada é bastante comum para validar que um email foi enviado, como se tivesse uma origem num dominio.'
    },
    SOA: {
      title: 'Entradas tipo SOA',
      description: 'A entrada tipo SOA é muito importante ao configurar o DNS, ela informa quem é o responsável pelo domínio.'
    },
    SRV: {
      title: 'Entradas tipo SRV',
      description: 'Essa entrada permite a localização com serviços disponíveis no domínio, inclusive protocolos e portas. Um exemplo de serviço é o de voz sobre ip (VoIP).'
    },
    PTR: {
      title: 'Entradas tipo PTR',
      description: 'São utilizadas em pesquisas de DNS reverso. Quando um usuário tenta acessar um nome de domínio em seu navegador, ocorre uma pesquisa de DNS, correspondendo o nome de domínio ao endereço de IP.'
    }
  },
  form: {
    regexHint: 'Pode conter letras, números ou os caracteres: ".", "-" ou "_"',
    type: {
      label: 'Tipo de Entrada',
      placeholder: 'Selecione o tipo da entrada'
    },
    name: {
      label: 'Nome / Host / Alias',
      placeholder: 'em branco'
    },
    priority: {
      label: 'Prioridade'
    },
    weight: {
      label: 'Peso'
    },
    port: {
      label: 'Porta'
    },
    content: {
      label: 'Conteúdo / Valor / Aponta para',
      contentItem: '#Conteúdo {position}',
      objectLabel: 'Objeto',
      ipv4Hint: 'Conteúdo no formato IPV4',
      ipv6Hint: 'Conteúdo no formato IPV6'
    },
    ttlInfo: 'Todas as entradas adicionadas possuirão o mesmo valor de TTL 3600'
  },
  validations: {
    invalidIP: 'O IP informado não é valido.',
    invalid: 'O conteúdo deve ser uma url válida.',
    invalidName: 'A entrada não pode finalizar com a zona.',
    duplicate: 'Conteúdo duplicado.'
  },
  actions: {
    addContent: 'Adicionar Conteúdo',
    removeContent: 'Remover Conteúdo',
    create: 'Salvar Configurações',
    edit: 'Editar Entrada',
    cancel: 'Cancelar'
  },
  alerts: {
    create: {
      success: 'Entrada criada com sucesso',
      error: 'Erro ao tentar criar a entrada. Tente novamente em breve',
      notFound: 'Erro ao tentar criar a entrada. Não encontrados o domínio selecionado durante a criação.',
      conflict: 'Você já possui uma entrada com esse mesmo nome. Para continuar, insira um nome diferente ou altere o tipo da entrada.'
    },
    update: {
      success: 'Entrada alterada com sucesso',
      error: 'Erro ao tentar alterar a entrada. Tente novamente em breve',
      notFound: 'Erro ao tentar alterar a entrada. Não encontrados o domínio selecionado durante a criação.'
    }
  },
  modal: {
    info: {
      close: 'Fechar',
      title: 'Tempo de propagação das alterações',
      firstPhrase: 'Lembre-se de que suas alterações podem levar até 48 horas para se propagar por toda internet e serem disponibilizadas. Qualquer dúvida, entre em contato através dos nossos canais de atendimento.'
    },
    withoutAuthority: {
      close: 'Fechar',
      title: 'Provedor de DNS externo identificado',
      firstPhrase: 'Identificamos que você está usando outro provedor de DNS e, por isso, as novas configurações podem não funcionar.',
      secondPhrase: 'Para suas alterações funcionarem adequadamente, recomendamos que ',
      link: 'use os nameservers da Locaweb',
      thirdPhrase: ' ou faça as mudanças desejadas em seu outro provedor. Qualquer dúvida, entre em contato através dos nossos canais de atendimento.'
    }
  }
}
