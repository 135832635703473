import faq from './faq'
import records from './records'
import zones from './zones'
import staticPages from './static'

export default {
  faq,
  records,
  zones,
  static: staticPages
}
