/* eslint-disable no-console */

import { register } from 'register-service-worker'

if (process.env.NODE_ENV === 'production') {
  register('/service-worker.js', {
    ready () {},
    registered () {},
    cached () {},
    updatefound () {},
    updated (registration) {
      registration.update()
      window.location.reload()
    },
    offline () {},
    error () {}
  })
}
