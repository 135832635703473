<template>
  <div>
    <p class="pb-2" v-html="t$.content"></p>
    <div v-if="t$.type !== 'text only'" class="border-hairline border-gray-scale200 rounded-md pa-4 d-flex align-center justify-start">
      <Icon :icon="getIcon()" color="primary"/>
      <Link :href="t$.linkUrl" :text="t$.linkText" blank color="primary" :ga="trackEvent()" class="text-paragraph-1 font-weight-regular ml-1"/>
    </div>
  </div>
</template>

<script>
import { Icon, Link, useLocale } from '@locaweb/design-system-vue'
import ga from '@ga'

export default {
  name: 'FAQContent',
  components: {
    Icon,
    Link
  },
  props: ['itemNumber', 'pageName'],
  setup (props) {
    const t$ = useLocale('faq').items[props.itemNumber]

    const getIcon = () => {
      return t$.type === 'video' ? '$objects-videocamera-filled' : '$symbols-bookmark-filled'
    }

    const trackEvent = page => {
      const gaEvent = ga.faq.linkClickEvent(t$.linkText)
      return {
        ...gaEvent,
        category: gaEvent.category.replaceValues({ page: props.pageName })
      }
    }

    return {
      t$,
      ga,
      getIcon,
      trackEvent
    }
  }
}
</script>
