import { httpClient, mountUrl } from '@locaweb/design-system-vue'

const BASE_URL = '/v1/zones/$domain'
const RECORD_PATH = `${BASE_URL}/records`

const createRecord = (record, login) => {
  return httpClient.post(mountUrl(RECORD_PATH, record), record, { headers: { ...login && { impersonate_login: login } } })
}

const updateRecord = (record, login) => {
  return httpClient.put(mountUrl(RECORD_PATH, record), record, { headers: { ...login && { impersonate_login: login } } })
}

const deleteRecord = (record, login) => {
  return httpClient.delete(mountUrl(RECORD_PATH, record), {
    headers: {
      type: record.type,
      name: record.name,
      impersonate_login: login
    }
  })
}

export {
  createRecord,
  deleteRecord,
  updateRecord
}
