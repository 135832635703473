import RecordTypeEnum from '@/enums/RecordTypeEnum'

export function useMapRecord (record, isEdit) {
  const emptyState = {
    priority: 0,
    weight: 0,
    port: 0
  }
  if (isEdit && record.value?.content) {
    if (record.value?.type === RecordTypeEnum.MX) {
      record.value.content = record.value.content.map((content, index) => ({
        id: index,
        ...emptyState,
        priority: content.split(' ')[0],
        value: content.split(' ')[1]
      }))
    } else if (record.value?.type === RecordTypeEnum.SRV) {
      record.value.content = record.value.content.map((content, index) => ({
        id: index,
        priority: content.split(' ')[0],
        weight: content.split(' ')[1],
        port: content.split(' ')[2],
        value: content.split(' ')[3]
      }))
    } else {
      record.value.content = record.value.content.map((content, index) => ({
        id: index,
        ...emptyState,
        value: content
      }))
    }
  }
}
