const apiUrl = (hostname = window.location.hostname, env = process.env.NODE_ENV) => {
  const url = {
    protocol: `http${hostname === 'localhost' ? '' : 's'}://`,
    hostname,
    ...env === 'development' && { port: hostname === 'localhost' ? ':80' : ':3001' },
    path: process.env.VUE_APP_API_BASE_URL
  }
  return Object.values(url).join('')
}

export {
  apiUrl
}
