import TheMainLayout from '@/components/TheMainLayout/TheMainLayout'
import PageNotFound from '@/views/static/PageNotFound'
import ZoneRoutes from '@/views/zone/router'
import RecordsRoutes from '@/views/record/router'
import { impersonateFilter, shouldBeAuthenticate } from '@/router/filters'
import { apiUrl } from '@/config'
import VueCookies from 'vue-cookies'

const routes = [
  {
    path: '/logout',
    beforeEnter: () => {
      VueCookies.remove(process.env.VUE_APP_COOKIE_NAME, '/', process.env.VUE_APP_COOKIE_DOMAIN)
      window.location = `${apiUrl}/logout`
    }
  },
  {
    path: '/',
    component: TheMainLayout,
    children: [
      ...ZoneRoutes,
      ...RecordsRoutes,
      {
        path: '*',
        name: 'not-found',
        component: PageNotFound,
        title: 'static.notFound.title'
      }
    ].map(route => ({ ...route, ...route.validateImpersonate && { beforeEnter: impersonateFilter } })),
    beforeEnter: shouldBeAuthenticate
  }
]

export default routes
