<template>
  <App>
    <Header imageLogo :userInfo="auth.userInfo"/>
    <Main class="background" :appVersion="version"/>
  </App>
</template>

<script>
import { App, Header, Main } from '@locaweb/design-system-vue'
import { authStore } from '@/store/auth'

const version = require('../../../package.json').version

export default {
  /**
   *  Um padrão do Vue é usar nome composto para os componentes
   *  Componentes que são renderizados apenas uma vez na aplicação devem ser precedidos pelo 'The'
   */
  name: 'TheMainLayout',
  components: {
    Header,
    Main,
    App
  },
  setup () {
    const auth = authStore()
    return { version, auth }
  }
}
</script>
