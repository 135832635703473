<template>
  <Modal :title="t$.title" :icon="icon" v-model="propagationModal" :title-section-mobile="t$.title">
    <p class="text-paragraph-1 text-justify" v-text="t$.firstPhrase"/>
    <slot v-if="!authority">
      <br>
      <p class="text-paragraph-1 text-justify">{{t$.secondPhrase}}<Link blank href="https://www.locaweb.com.br/ajuda/wiki/como-alterar-os-dns-dos-dominios-registrados-na-locaweb-registro-de-dominio/" class="text-paragraph-1 text-justify" :text="t$.link"/>{{t$.thirdPhrase}}</p>
    </slot>
    <template #append>
      <Button color="primary" :label="t$.close" @click="toggleModal"/>
    </template>
  </Modal>
</template>

<script>
import { Button, Modal, Link, useLocale } from '@locaweb/design-system-vue'

export default {
  name: 'PropagationModal',
  components: {
    Modal,
    Button,
    Link
  },
  props: {
    authority: Boolean
  },
  setup (props) {
    const type = props.authority ? 'info' : 'withoutAuthority'
    const icon = props.authority ? '' : '$communication-chat-alert-filled'
    const t$ = useLocale('records.form.modal.' + type)
    return { icon, t$ }
  },
  data () {
    return {
      propagationModal: false
    }
  },
  methods: {
    toggleModal () {
      localStorage.removeItem('open modal for ' + localStorage.getItem('domain'))
      localStorage.removeItem('domain')
      this.propagationModal = !this.propagationModal
    }
  }
}
</script>
