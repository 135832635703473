import { httpClient, mountUrl } from '@locaweb/design-system-vue'

const BASE_URL = '/v1/zones'
const FIND_PATH = `${BASE_URL}/$domain`

const findAll = ({ pagination, search, sortBy, isAdmin }) => {
  return httpClient.get(BASE_URL, {
    params: {
      page: pagination.value.page,
      itemsPerPage: pagination.value.itemsPerPage,
      ...search.value && !isAdmin && { zone: search.value },
      ...sortBy.value && { sort: sortBy.value.domain }
    },
    ...isAdmin && { headers: { impersonate_login: search.value } }
  })
}

const find = ({ param, pagination, login, sortBy }) => {
  return httpClient.get(mountUrl(FIND_PATH, { domain: param }), {
    params: {
      page: pagination.value.page,
      itemsPerPage: pagination.value.itemsPerPage,
      ...sortBy.value && {
        ...sortBy.value.type && { sortType: sortBy.value.type },
        ...sortBy.value.name && { sortEntry: sortBy.value.name }
      }
    },
    ...login && { headers: { impersonate_login: login.value } }
  })
}

export {
  find,
  findAll
}
