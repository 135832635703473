import { deleteRecord } from '@/views/record/api/record.api'
import { useNotification } from '@locaweb/design-system-vue'
export default function useHandleDelete ({ t$, record, loading, domain, login }) {
  const $notify = useNotification()
  async function handleDeleteRecord (sucessCallback) {
    record.value.domain = domain
    loading.value = true
    try {
      await deleteRecord(record.value, login?.value)
      sucessCallback && sucessCallback()
      $notify.show({ message: t$.alerts.delete.success, state: 'success' })
    } catch (e) {
      $notify.show({ message: t$.alerts.delete.error, state: 'error' })
    } finally {
      loading.value = false
    }
  }
  return handleDeleteRecord
}
