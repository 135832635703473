export default {
  edit: {
    action: 'click_button',
    category: '[Zona de DNS][TIPO {type}] Lista de entradas DNS',
    label: 'Editar Entrada'
  },
  remove: {
    action: 'click_button',
    category: '[Zona de DNS][TIPO {type}] Lista de entradas DNS',
    label: 'Excluir entrada'
  },
  modal: {
    remove: {
      action: 'click_button',
      category: '[Zona de DNS [TIPO {type}] Modal exclusão',
      label: 'Excluir entrada'
    },
    cancel: {
      action: 'click_button',
      category: '[Zona de DNS [TIPO {type}] Modal exclusão',
      label: 'Talves mais tarde'
    }
  },
  form: {
    action: {
      action: 'click_button',
      category: '[Zona de DNS][TIPO {type}] {action} Entrada DNS',
      label: '{action} Entrada'
    },
    typeChange: {
      action: 'click_select',
      category: '[Zona de DNS][TIPO {type}] {action} Entrada DNS',
      label: 'Tipo de entrada: {type}'
    },
    addContent: {
      action: 'click_button',
      category: '[Zona de DNS][TIPO {type}] {action} Entrada DNS',
      label: 'Adicionar conteúdo'
    },
    removeContent: {
      action: 'click_button',
      category: '[Zona de DNS][TIPO {type}] {action} Entrada DNS',
      label: 'Remover conteúdo'
    },
    entryName: {
      trigger: 'blur',
      action: 'enter_input',
      category: '[Zona de DNS][TIPO {type}] {action} Entrada DNS',
      label: 'Nome da Entrada'
    },
    content: {
      trigger: 'blur',
      action: 'enter_input',
      category: '[Zona de DNS][TIPO {type}] {action} Entrada DNS',
      label: 'Conteúdo'
    },
    priority: {
      trigger: 'blur',
      action: 'enter_input',
      category: '[Zona de DNS][TIPO {type}] {action} Entrada DNS',
      label: 'Prioridade'
    },
    weight: {
      trigger: 'blur',
      action: 'enter_input',
      category: '[Zona de DNS][TIPO {type}] {action} Entrada DNS',
      label: 'Peso'
    },
    port: {
      trigger: 'blur',
      action: 'enter_input',
      category: '[Zona de DNS][TIPO {type}] {action} Entrada DNS',
      label: 'Porta'
    }
  }
}
