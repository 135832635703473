<template>
  <Row>
    <Col v-for="(value, key) in t$" :key="key" cols="12" sm="4">
      <Card outlined v-size.min.height="234">
        <div class="d-flex flex-column justify-center">
          <div class="d-flex justify-center">
            <Icon :class="getStateClass(zone[key])" :color="iconColor(zone[key])" :icon="icons[key]"/>
          </div>
          <header class="mt-4 text-center">
            <strong class="text-paragraph-1 font-family-primary" v-text="stateTranslate(key).title"/>
          </header>
          <p class="text-paragraph-2 text-center mt-2 gray--text text--scale-500" v-text="stateTranslate(key).description"/>
          <div class="d-flex justify-center mt-n1">
            <Button small v-if="hasAction(key)" v-bind="actionProps(key)" target="_black" class="mt-6" text
            :label="stateTranslate(key).action" color="primary" />
          </div>
        </div>
      </Card>
    </Col>
  </Row>
</template>

<script>
import { Row, Col, Card, useLocale, Icon, Button } from '@locaweb/design-system-vue'

export default {
  name: 'WhoIs',
  components: { Row, Col, Card, Icon, Button },
  props: {
    zone: {
      type: Object,
      required: true
    }
  },
  setup (props) {
    const icons = {
      register: '$localization-globe-outline',
      authority: '$server-dns-outline',
      servers: '$server-dns-outline'
    }
    const t$ = useLocale('records.list.whoIs')

    function getStateClass (state) {
      return {
        'pa-2 rounded-circle': true,
        'primary lighten-2': !state,
        'success lighten-1': state
      }
    }
    function iconColor (state) {
      return state ? 'success' : 'primary'
    }

    function stateTranslate (key) {
      return t$[key][props.zone[key].toString()]
    }

    function hasAction (key) {
      return key === 'register' && ((!props.zone[key] && !props.zone.register) || (props.zone[key] && props.zone.servers))
    }

    function actionProps (key) {
      return {
        ...!props.zone[key] && { href: `${process.env.VUE_APP_REGISTRO_CHECKOUT}/?domain_ids=${props.zone.name}` },
        ...props.zone[key] && { href: `${process.env.VUE_APP_DOMAIN_PANEL_URL}/${props.zone.name}` }
      }
    }

    return { t$, icons, getStateClass, iconColor, stateTranslate, actionProps, hasAction }
  }
}
</script>
