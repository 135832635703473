<template>
  <div class="d-flex flex-column">
    <Heading v-bind="headerProps" :divider="false" icon="$server-dns-filled">
      <template #subtitle>
        <div class="mt-4">
          <HTMLRender class="text-h3 font-weight-medium" :locale="t$" path="subtitle.welcome" :values="welcomeValues"/>
          <p class="text-paragraph-1" v-text="headerDescription"/>
        </div>
      </template>
    </Heading>
    <Card outlined v-bind="cardProps" v-size.min.height="400">
      <Row v-if="hasZones || isAdmin">
        <Col cols="12" sm="6" md="6">
        <strong class="text-h3" v-text="t$.zones.title" />
        </Col>
        <Col cols="12" sm="6" md="6" v-if="hasZones || isAdmin" tag="form" :aria-label="t$.zones.filter.label"
          @submit.prevent>
        <TextField v-bind="filterProps" appendIcon="$objects-magnify-outline" :debounce="600" :ga="ga.search"
          v-model="search" />
        </Col>
      </Row>
      <div class="pa-4 mt-4 d-flex justify-center" v-if="adminEmptyState">
        <div>
          <Picture width="250" :src="emptySearch" />
        </div>
      </div>
      <RequestComponent class="pa-1" :request-function="findAll" response-key="zones" table external-action
                        :tableHeader="tableHeader" ref="list" :result-text="pageResult" :create-link="createLink"
                        :noDataText="t$.table.emptyText" @finish="handleLoad" v-else :isAdmin="isAdmin"
                        serverPagination :serverSearch="search">
        <template v-slot:item.domain="{item}">
          <div class="d-flex align-center gap-lg">
            <span v-text="item.domain"/>
            <Tag v-if="item.developer" small text="Desenvolvedor"/>
          </div>
        </template>
        <template #actions="{item}">
          <Button color="primary" small :ga="ga.manage" text :label="t$.table.actions.create" :to="adminRoute(item)" @click="manageHandler"/>
        </template>
      </RequestComponent>

    </Card>
  </div>
</template>

<script>
import RequestComponent from '@/components/RequestComponent'
import { authStore } from '@/store/auth'
import { findAll } from '@/views/zone/api/zone.api'
import ga from '@ga'
import emptySearch from '@/assets/illustration/search-people.svg'
import {
  Card,
  Col,
  Heading,
  HTMLRender,
  Row, Tag, TextField,
  useLocale,
  Button,
  Picture,
  useStorage
} from '@locaweb/design-system-vue'
import { computed, ref } from 'vue'

/**
 *  Componente de listagem de zonas
 */
export default {
  name: 'ZoneList',
  components: { RequestComponent, Heading, Card, TextField, Row, Col, HTMLRender, Tag, Button, Picture },
  setup () {
    const auth = authStore()
    // Variável com os textos a serem usados no componente
    const t$ = useLocale('zones')
    const welcomeValues = { name: auth.userName }

    const search = ref('')
    const hasZones = ref(false)
    const cardProps = computed(_ => hasZones.value || auth.isAdmin ? undefined : { color: 'transparent' })
    const handleLoad = ({ data, headers }) => {
      hasZones.value = (data && !data.isEmpty()) || (search.value && data.isEmpty())
    }

    const tableHeader = [
      {
        text: t$.table.header.zone,
        align: 'start',
        value: 'domain'
      },
      { text: t$.table.header.actions, align: 'end', value: 'actions', sortable: false, width: 40 }
    ]
    const headerProps = computed(() => ({
      title: t$.title,
      ...hasZones.value
    }))

    const headerDescription = auth.isAdmin ? t$.subtitle.adminDescription : t$.subtitle.description
    const filterProps = {
      'aria-label': t$.zones.filter[auth.isAdmin ? 'user' : 'domain'].label,
      placeholder: t$.zones.filter[auth.isAdmin ? 'user' : 'domain'].placeholder
    }

    const adminRoute = (item) => ({
      name: 'domainDetail',
      params: { domain: item.domain }
    })

    const adminEmptyState = computed(_ => auth.isAdmin && !search.value)

    const createLink = process.env.VUE_APP_DOMAIN_PANEL_URL

    const pageResult = computed(_ => ({
      ...t$.result,
      empty: {
        ...t$.result.empty,
        description: auth.isAdmin ? t$.result.empty.adminDescription.replaceValues({ search: search.value }) : t$.result.empty.description
      }
    }))

    function manageHandler () {
      const [, setValue] = useStorage({ key: 'impersonate_login', initialValue: null, type: 'sessionStorage' })
      setValue(search.value)
    }

    return {
      t$,
      welcomeValues,
      findAll,
      tableHeader,
      adminRoute,
      search,
      headerProps,
      handleLoad,
      hasZones,
      cardProps,
      createLink,
      ga: ga.zone,
      headerDescription,
      filterProps,
      isAdmin: auth.isAdmin,
      emptySearch,
      adminEmptyState,
      pageResult,
      manageHandler
    }
  }
}
</script>
