import { between, helpers, ipAddress, maxLength, required } from 'vuelidate/lib/validators'
import { GenericRequiredValidation, getMessage } from '@locaweb/design-system-vue'
import RecordTypeEnum from '@/enums/RecordTypeEnum'

let validationsMessages

const model = Object.freeze({
  name: '',
  content: [
    {
      id: 0,
      value: '',
      priority: 0,
      port: 0,
      weight: 0
    }
  ],
  type: 'A'
})
const nameRegex = helpers.regex('alpha', /^@$|^[a-zA-Z\d\\._-]+$/)
const contentRegex = helpers.regex('alpha', /^(([\w])?([a-z\d])+(([_-])?([a-z\d])+)*\.)*([a-z\d]+([-_][a-z\d]+)*\.)+[a-z]{2,}$/i)

function ipv6Regex (value) {
  const regex = /(([\da-fA-F]{1,4}:){7}[\da-fA-F]{1,4}|([\da-fA-F]{1,4}:){1,7}:|([\da-fA-F]{1,4}:){1,6}:[\da-fA-F]{1,4}|([\da-fA-F]{1,4}:){1,5}(:[\da-fA-F]{1,4}){1,2}|([\da-fA-F]{1,4}:){1,4}(:[\da-fA-F]{1,4}){1,3}|([\da-fA-F]{1,4}:){1,3}(:[\da-fA-F]{1,4}){1,4}|([\da-fA-F]{1,4}:){1,2}(:[\da-fA-F]{1,4}){1,5}|[\da-fA-F]{1,4}:((:[\da-fA-F]{1,4}){1,6})|:((:[\da-fA-F]{1,4}){1,7}|:)|fe80:(:[\da-fA-F]{0,4}){0,4}%[\da-zA-Z]+|::(ffff(:0{1,4})?:)?((25[0-5]|(2[0-4]|1?[\d])?[\d])\.){3}(25[0-5]|(2[0-4]|1?[\d])?[\d])|([\da-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1?[\d])?[\d])\.){3}(25[0-5]|(2[0-4]|1?[\d])?[\d]))/gm
  return regex.test(value) && value.split('::').length < 2
}
function typeValidation (value) {
  return Object.values(RecordTypeEnum).includes(value)
}

function isUnique (value, it) {
  return !this.record.content.filter((c, index) => index !== it.id).find(c => c.value === value)
}

function validations () {
  validationsMessages = getMessage('records.form.validations')
  return {
    record: {
      name: {
        maxLength: maxLength(223),
        regex: nameRegex,
        ...this.record?.type === 'CNAME' && { required }
      },
      type: {
        required,
        typeValidation
      },
      content: {
        $each: {
          value: {
            maxLength: maxLength(6000),
            ...this.record?.type === 'A' && { ipAddress },
            ...this.record?.type === 'AAAA' && { ipAddress: ipv6Regex },
            ...['CNAME', 'MX', 'NS'].includes(this.record?.type) && { regex: contentRegex },
            required,
            isUnique
          },
          ...['MX', 'SRV'].includes(this.record?.type) && {
            priority: { required, between: between(0, 9999) }
          },
          ...this.record?.type === 'SRV' && {
            port: { required },
            weight: { required }
          }
        }
      }
    }
  }
}

const validationMessages = {
  typeErroMessage () {
    return GenericRequiredValidation(this.$v.record.type)
  },
  nameErroMessage () {
    let errors = []
    if (this.record.type === 'CNAME') {
      errors = errors.concat(GenericRequiredValidation(this.$v.record.name))
    }
    this.$v.record.name && !this.$v.record.name.regex && errors.push(validationsMessages.invalid)
    this.$v.record.name && this.$v.record.name.$model.endsWith(this.domain) && errors.push(validationsMessages.invalidName)
    return errors
  },
  contentErrorMessage () {
    return Object.values(this.$v.record?.content.$each.$iter || []).map($it => {
      let errors = []
      errors = errors.concat(GenericRequiredValidation($it.value))
      $it.value.required && Object.keys($it.value).includes('ipAddress') && !$it.value.ipAddress && errors.push(validationsMessages.invalidIP)
      $it.value.required && Object.keys($it.value).includes('regex') && $it.value.required && !$it.value.regex && errors.push(validationsMessages.invalid)
      $it.value.required && !$it.value.isUnique && errors.push(validationsMessages.duplicate)
      return errors
    })
  }
}

export { model, validations, validationMessages }
