import Chance from 'chance'

export default (server) => {
  const chance = new Chance()
  const domain = chance.domain({ tld: 'locaweb.com.br' })
  for (let i = 1; i <= 1000; i++) {
    server.create('zone', {
      id: i,
      provisioning_id: i + 1,
      domain,
      name: domain,
      register: false,
      authority: true,
      servers: false,
      developer: chance.bool({ likelihood: 50 })
    })
  }
}
