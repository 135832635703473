import { useVueRouter } from '@locaweb/design-system-vue'
import { onBeforeMount } from 'vue'

export function useValidateDomain (domain) {
  const { $router } = useVueRouter()
  const domainRegex = /^([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}$/

  onBeforeMount(_ => {
    if (!domainRegex.test(domain)) {
      $router.push({ name: 'not-found' })
    }
  })
}
