/**
 * Arquivo responsável por configurar o mock da api no front-end usando o MIRAGEJS
 * Caso não vá realizar o mock, a pasta pode ser ignorada
 * @see https://miragejs.com/
 */
import { ActiveModelSerializer, belongsTo, createServer, hasMany, Model } from 'miragejs'
import routes from '@/server/routes'
import seeds from '@/server/seeds'
import VueCookies from 'vue-cookies'

const shouldConvert = (key, value) => {
  return (key === 'id' || key.endsWith('Id')) && typeof value === 'string' && Number(value)
}

const mapData = (data) => {
  if (Array.isArray(data)) {
    return data.map((item) => mapData(item))
  }

  if (typeof data === 'object' && data !== null && !data.getTime) {
    return Object.entries(data)
      .reduce((acc, [key, value]) => {
        const newValue = shouldConvert(key, value) ? +value : mapData(value)
        return {
          ...acc,
          [key]: newValue
        }
      }, {})
  }

  return data
}

const makeServer = ({ environment = 'development' } = {}) => {
  VueCookies.set(process.env.VUE_APP_COOKIE_NAME, process.env.VUE_APP_RESELLER_TOKEN)
  const server = createServer({
    environment,
    serializers: {
      application: ActiveModelSerializer.extend({
        serialize () {
          const json = ActiveModelSerializer.prototype.serialize.apply(this, arguments)
          return mapData(json)
        }
      })
    },
    models: {
      record: Model.extend({
        zone: belongsTo()
      }),
      zone: Model.extend({
        records: hasMany()
      })
    },
    seeds,
    routes
  })

  return server
}

export {
  makeServer
}
