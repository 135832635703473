<template>
  <EmptyState class="page-result mt-8" v-bind="propsData">
    <template #illustration>
      <Picture maxWidth="400" class="mb-12" :src="illustration"/>
    </template>
    <slot v-if="error">
      <ul class="page-result__list" v-if="steps">
        <li v-for="(step, index) in steps" :key="index"
            class="border-hairline mb-4 rounded-md border-gray-scale300 pa-5 d-flex gap-md align-center">
          <span class="d-inline-block secondary rounded-circle page-result__step-indicator" v-text="index + 1"/>
          <span class="text-left text-paragraph-2 page-result__step" v-text="step"/>
        </li>
      </ul>
    </slot>
    <template v-if="actionText" #actions>
      <Button :title="actionText" v-text="actionText" color="primary" v-bind="actionProps" v-on="actionEvents"/>
    </template>
  </EmptyState>
</template>

<script>
import { Button, EmptyState, Picture } from '@locaweb/design-system-vue'
import EmptyResultIllustration from '@/assets/results/empty.svg'
import ErrorResultIllustration from '@/assets/results/server_down.svg'
import NotFoundIllustration from '@/assets/illustration/not_found.svg'

export default {
  name: 'PageResult',
  components: {
    EmptyState,
    Button,
    Picture
  },
  props: {
    error: Boolean,
    textResults: {
      type: Object,
      required: true
    },
    actionRoute: [String, Object],
    externalAction: Boolean,
    errorStatusCode: {
      type: Number,
      default: 500
    },
    values: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    errorKey () {
      return this.errorStatusCode === 404 ? 'notFound' : 'error'
    },
    steps () {
      return this.textResults[this.error ? this.errorKey : 'empty'].steps || null
    },
    actionText () {
      return this.textResults[this.error ? this.errorKey : 'empty'].action
    },
    actionProps () {
      return {
        ...!this.externalAction && this.actionRoute && { to: this.actionRoute },
        ...this.externalAction && this.actionRoute && { href: this.actionRoute, target: '_blank' }
      }
    },
    propsData () {
      return {
        title: this.textResults[this.error ? this.errorKey : 'empty'].title?.replaceValues(this.values),
        subtitle: this.textResults[this.error ? this.errorKey : 'empty'].description?.replaceValues(this.values)
      }
    },
    illustration () {
      if (this.error) {
        return this.errorStatusCode === 404 ? NotFoundIllustration : ErrorResultIllustration
      }
      return EmptyResultIllustration
    },
    actionEvents () {
      return {
        click: () => this.$emit('add')
      }
    }
  }
}
</script>

<style scoped lang="scss">
.page-result__list{
  list-style: none;
  .page-result__step-indicator{
    width: 21px;
    height: 21px;
    flex-basis: 21px;
    > span {
      flex: 1
    }
  }
  .page-result__step{
    flex: 1
  }
}
</style>
