<template>
  <div class="pa-5 mt-2 gray scale100">
    <strong class="text-h4 font-weight-medium" v-text="recordDescription.title"></strong>
    <p class="text-paragraph-2 rounded-md mt-4" v-text="recordDescription.description"></p>
  </div>
</template>

<script>
import { useLocale } from '@locaweb/design-system-vue'
import { computed } from 'vue'

export default {
  name: 'RecordDescription',
  props: {
    type: {
      type: String,
      required: true
    }
  },
  setup (props) {
    const t$ = useLocale('records.form.recordTypes')
    const recordDescription = computed(_ => t$[props.type])

    return {
      recordDescription
    }
  }
}
</script>
