// Importe do httpClient do DS configurado como parâmetro http no arquivo em config/index.js
import { httpClient } from '@locaweb/design-system-vue'

const WHOAMI_URL = '/token'

const validateToken = () => {
  return httpClient.get(WHOAMI_URL)
}

export {
  validateToken
}
