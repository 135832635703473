import { useNotification, useVueRouter } from '@locaweb/design-system-vue'
import RecordTypeEnum from '@/enums/RecordTypeEnum'
import { ZonesRoutesPath } from '@/views/zone/router'
import { updateRecord, createRecord } from '@/views/record/api/record.api'
function fixTXTValue (record) {
  if (record.value.type === RecordTypeEnum.TXT) {
    record.value.content.forEach(c => {
      c.value = `"${c.value.replace(/['"]+/g, '')}"`
    })
  }
}

function showModal (record) {
  localStorage.setItem('open modal for ' + record.value.domain, true)
}

export default function useHandleSave ({ $v, loading, record, isEdit, t$, serverErrors, domain, login }) {
  const $notify = useNotification()
  const { $router } = useVueRouter()
  async function handleSaveRecord () {
    const actionKey = isEdit ? 'update' : 'create'
    $v.value.record.$touch()
    if (!$v.value.record.$invalid) {
      loading.value = true
      record.value.domain = domain

      fixTXTValue(record)

      try {
        isEdit ? await updateRecord(record.value, login?.value) : await createRecord(record.value, login?.value)
        $notify.show({ message: t$.alerts[actionKey].success, state: 'success' })
        showModal(record)
        $router.push(ZonesRoutesPath.DETALHAR.path.replace(':domain', domain))
      } catch (e) {
        if (e.response?.status === 400) {
          serverErrors.value = e.response.data?.errors
        }
        const error = e.response?.status === 404 ? 'notFound' : e.response?.status === 409 ? 'conflict' : 'error'
        if (e.response?.status === 404) {
          $router.push(ZonesRoutesPath.LISTAR.path)
        }
        $notify.show({ message: t$.alerts[actionKey][error], state: 'error' })
      } finally {
        loading.value = false
      }
    }
  }
  return handleSaveRecord
}
