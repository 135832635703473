<template>
  <div>
    <Heading :title="title" :divider="false" :subtitle="t$.subtitle">
      <template #action>
        <FAQDrawer pageName="Entradas DNS"/>
      </template>
    </Heading>
    <Card>
      <form class="mt-4" aria-describedby="record-description" id="record-form" @submit.prevent.stop="handleSaveRecord">
        <Row>
          <Col id="record_id" cols="12" md="5">
            <Row>
              <Col cols="12">
                <SelectField :label="t$.form.type.label" :items="recordTypes" :placeholder="t$.form.type.placeholder"
                             required v-model="record.type" :errorMessages="typeErroMessage" :readonly="isEdit"/>
              </Col>
              <Col cols="12">
                <TextField :label="t$.form.name.label" :placeholder="t$.form.name.placeholder" :required="record.type === 'CNAME'"
                           :suffix="domain" :maxLength="223" :hint="t$.form.regexHint" v-model="record.name"
                           :errorMessages="nameErroMessage" :readonly="isEdit" :ga="gaEvents.entryName"/>
              </Col>
            </Row>
          </Col>
          <Col cols="12" md="7" class="d-none d-md-block">
            <RecordDescription :type="record.type"/>
          </Col>
          <Col id="record_content" cols="12" tag="ul">
            <component :is="contentWrapperProps.is" v-bind="contentWrapperProps" v-for="(content,index) in record.content"
                       :key="`content-${index + 1}`" :title="contentTitle(index)">
              <template #headerActions>
                <Button :title="t$.actions.removeContent" iconSize="dense" icon="$tool-trash-outlined" color="primary"
                        @click="handleRemoveContent(index)" v-if="displayMobileAction(index)" :ga="removeContent"/>
              </template>
              <ContentForm :type="record.type" :$v="v$" :index="index" :model="record" @remove="handleRemoveContent"
              :isEdit="isEdit"/>
            </component>
          </Col>
          <Col cols="12" v-if="enableMultiContent">
            <Button class="ml-1" text color="primary" @click="handleNewContent" prependIcon="$symbols-plus-filled"
            :label="t$.actions.addContent" :ga="gaEvents.addContent"/>
            </Col>
            <Col cols="12">
            <Divider/>
            <p class="mt-4 text-paragraph-2" id="record-description" v-text="t$.form.ttlInfo"/>
          </Col>
        </Row>
      </form>
      <template #actions>
        <Button :ga="gaEvents.action" :loading="loading" form="record-form" type="submit" color="primary" :label="actionLabel" :disabled="disableSubmit"/>
      </template>
    </Card>
  </div>
</template>

<script>
import {
  Button,
  Card,
  Col,
  Divider,
  Heading,
  Icon,
  InfoText,
  Row,
  SelectField,
  TextField,
  useLocale,
  useStorage,
  useValidation
} from '@locaweb/design-system-vue'
import { computed, onUnmounted } from 'vue'
import { validationMessages, validations } from '@/views/record/api/model/Record'
import RecordTypeEnum from '@/enums/RecordTypeEnum'
import RecordDescription from '@/views/record/form/components/RecordDescription'
import { useRecordAPI } from '@/views/record/form/functions/useRecordAPI'
import { useMountPage } from './functions/useMountPage'
import { useValidateDomain } from '@/functions/useValidateDomain'
import ContentForm from '@/views/record/form/components/ContentForm'
import FAQDrawer from '@/views/shared/FAQDrawer.vue'

export default {
  name: 'FormRecord',
  components: {
    FAQDrawer,
    ContentForm,
    RecordDescription,
    Heading,
    Card,
    Button,
    Row,
    Col,
    Divider,
    InfoText,
    SelectField,
    TextField,
    Icon
  },
  props: {
    domain: String
  },
  setup (props) {
    const [login] = useStorage({ key: 'impersonate_login', initialState: null, type: 'sessionStorage' })
    const [, setValue] = useStorage({ key: 'record', initialState: null, type: 'sessionStorage' })
    const v$ = useValidation()
    const t$ = useLocale('records.form')

    useValidateDomain(props.domain)
    const { gaEvents, contentWrapperProps, isEdit, record } = useMountPage(props)

    const title = computed(_ => t$[`${isEdit ? 'edit' : 'create'}Title`])
    const actionLabel = computed(_ => t$.actions[isEdit ? 'edit' : 'create'])

    const recordTypes = Object.values(RecordTypeEnum).filter(type => type !== 'SOA').map(r => ({ text: r, value: r }))

    function handleNewContent () {
      record.value.content.push({
        id: record.value.content.length,
        value: '',
        priority: 0,
        port: 0,
        weight: 0
      })
    }

    function displayMobileAction (index) {
      return index >= 1
    }

    function contentTitle (index) {
      return t$.form.content.contentItem.replaceValues({ position: index + 1 })
    }

    function handleRemoveContent (index) {
      record.value.content.splice(index, 1)
    }

    onUnmounted(_ => {
      setValue(null)
    })

    const [loading, handleSaveRecord] = useRecordAPI(t$, record, props.domain, v$, isEdit, login)

    const enableMultiContent = computed(_ => ![RecordTypeEnum.SOA, RecordTypeEnum.CNAME]
      .includes(record.value.type))

    const disableSubmit = computed(() => record.value.content.some(item => item.value === ''))

    return {
      t$,
      v$,
      title,
      record,
      actionLabel,
      isEdit,
      recordTypes,
      displayMobileAction,
      enableMultiContent,
      disableSubmit,
      handleRemoveContent,
      handleNewContent,
      contentWrapperProps,
      handleSaveRecord,
      contentTitle,
      loading,
      gaEvents
    }
  },
  validations () {
    return validations.bind(this)()
  },
  computed: {
    ...validationMessages
  }
}
</script>
<style lang="scss" scoped>
ul{
  list-style: none;
  padding: 14px!important;
}
</style>
