import { Response } from 'miragejs'

export default (server) => {
  server.get('/v1/zones', (schema, request) => {
    const qp = request.queryParams
    if (Object.keys(qp).length === 0) {
      return schema.zones.all()
    }
    const page = parseInt(qp.page) ? parseInt(qp.page) - 1 : 0
    const limit = parseInt(qp.itemsPerPage) || 10
    const start = page * limit
    const end = start + limit
    let data = schema.zones.all()
    const total = data.length
    data = data.slice(start, end).models.map(e => e.attrs)
    if (qp.zone) {
      data = data.filter(zone => zone.domain.startsWith(qp.zone))
    }
    return new Response(200, {
      'Page-Items': limit,
      'Current-Page': page + 1,
      'Total-Count': qp.zone ? data.length : total
    }, {
      zones: data
    })
  })

  server.get('/v1/zones/:domain', (schema, request) => {
    const domain = request.params.domain
    const zone = schema.zones.findBy({ domain })
    if (!zone) {
      return new Response(404)
    }
    const records = zone.records?.models?.map(m => m.attrs) ?? []
    const qp = request.queryParams
    if (Object.keys(qp).length === 0) {
      return { zone: { ...zone.attrs, records } }
    }
    const page = parseInt(qp.page) ? parseInt(qp.page) - 1 : 0
    const limit = parseInt(qp.itemsPerPage) || 10
    const start = page * limit
    const end = start + limit
    return new Response(200, {
      'Page-Items': limit,
      'Current-Page': page + 1,
      'Total-Count': records.length
    }, {
      zone: { ...zone.attrs, records: records.slice(start, end) }
    })
  })

  server.post('/v1/zones/:domain/records', (schema, request) => {
    const domain = request.params.domain
    const attrs = JSON.parse(request.requestBody)
    const zone = schema.zones.findBy({ domain })
    if (!zone) {
      return new Response(404)
    }
    attrs.zoneId = zone.attrs.id
    if (attrs.type === 'MX') {
      attrs.content = attrs.content.map(content => `${content.priority} ${content.value}`)
    } else if (attrs.type === 'SRV') {
      attrs.content = attrs.content.map(content => `${content.priority} ${content.weight} ${content.port} ${content.value}`)
    } else {
      attrs.content = attrs.content.map(c => c.value)
    }
    return schema.records.create(attrs)
  })

  server.delete('/v1/zones/:domain/records', (schema, request) => {
    const domain = request.params.domain
    const zone = schema.zones.findBy({ domain })
    const attrs = request.requestHeaders
    if (!zone) {
      return new Response(404)
    }
    const record = schema.records.findBy({ type: attrs.type, name: attrs.name, zoneId: zone.attrs.id })
    if (!record) {
      return new Response(404)
    }
    return record.destroy()
  })
}
