import VueCookies from 'vue-cookies'
import { validateToken } from '@/auth/auth.api'
import { authStore } from '@/store/auth'
import JwtDecode from 'jwt-decode'
import { apiUrl } from '@/config'

const getAuthUrl = () => {
  return `${apiUrl}/auth`
}
// Exemplo de filtro usando VueJs para redirecionar para o CAS caso o usuário não esteja logado
const shouldBeAuthenticate = async (to, from, next) => {
  const shouldAutenticate = await requireAuth()
  if (shouldAutenticate && process.env.NODE_ENV !== 'test') {
    window.open(
      getAuthUrl(),
      '_self'
    ) && next(false)
  } else {
    next(0)
  }
}

const requireAuth = async () => {
  // Caso o cookie definido na env não exista retorna true para que o usuário seja redirecionado para o CAS
  if (!VueCookies.isKey(process.env.VUE_APP_COOKIE_NAME)) return true
  // Caso o filtro carregue e o vue encotre o cookie dispatch-login enviado pela api ele evita de realizar a request para validar o token
  if (VueCookies.isKey('dispatch-login')) {
    VueCookies.remove('dispatch-login')
    authStore().login(JwtDecode(VueCookies.get(process.env.VUE_APP_COOKIE_NAME)))
    return false
  } else {
    try {
      // Caso o usuário aperte F5, o front-end vai até a api para validar o token JWT
      const { data } = await validateToken()
      authStore().login(data)
      return false
    } catch (e) {
      return true
    }
  }
}

const impersonateFilter = async (to, from, next) => {
  if (authStore().isAdmin) {
    sessionStorage?.getItem('impersonate_login') ? next() : next('/')
  } else {
    next()
  }
}

export {
  shouldBeAuthenticate,
  impersonateFilter
}
