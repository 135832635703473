import FormRecord from '@/views/record/form/FormRecord'

const prefix = '/'

export const RecordsRoutesPath = Object.freeze({
  ADICIONAR: {
    path: `${prefix}:domain/adicionar`,
    title: 'records.form.createTitle',
    name: 'recordAdd',
    component: FormRecord,
    props: true
  },
  EDITAR: {
    path: `${prefix}:domain/editar`,
    title: 'records.form.editTitle',
    name: 'recordEdit',
    component: FormRecord,
    props: true
  }
})

export default Object.values(RecordsRoutesPath)
