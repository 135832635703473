<template>
  <EmptyState :title="lc.title">
    <template #illustration>
      <v-img maxWidth="400" class="mb-12" :src="illustration"/>
    </template>
    <template #actions>
      <Button :title="lc.actionText" v-text="lc.actionText" color="primary" to="/"/>
    </template>
  </EmptyState>
</template>

<script>
import NotFoundIllustration from '@/assets/illustration/not_found.svg'
import { Button, EmptyState } from '@locaweb/design-system-vue'

export default {
  name: 'PageNotFound',
  components: {
    EmptyState,
    Button
  },
  computed: {
    lc () {
      return this.$lc('static.notFound')
    },
    illustration () {
      return NotFoundIllustration
    }
  }
}
</script>

<style scoped>

</style>
