<template>
  <Drawer :title="t$.title">
    <template #activator="{ on, attrs }">
      <Button outlined color="primary" :label="t$.buttonLabel" :ga="buttonEvent()" v-bind="attrs" v-on="on"/>
    </template>
    <Accordion :items="FAQItems" class="mt-11" @input="gaTrigger"/>
  </Drawer>
</template>

<script>
import { getCurrentInstance } from 'vue'
import { Accordion, Button, Drawer, useLocale } from '@locaweb/design-system-vue'
import ga from '@ga'
import FAQContent from './FAQContent.vue'

/* eslint-disable vue/no-unused-components */
export default {
  name: 'FAQDrawer',
  components: {
    Accordion,
    Button,
    Drawer,
    FAQContent
  },
  props: ['pageName'],
  setup (props) {
    const t$ = useLocale('faq')
    const { proxy: { $dga } } = getCurrentInstance()

    const FAQItems = t$.items.slice(0, t$.items.length).map((item, index) => ({
      label: item.label,
      content: FAQContent,
      props: { itemNumber: index, pageName: props.pageName }
    }))

    const buttonEvent = () => {
      return {
        ...ga.faq.openFAQ,
        category: ga.faq.openFAQ.category.replaceValues({ page: props.pageName })
      }
    }

    const accordionEvents = value => {
      const label = t$.items[value].label
      const gaEvent = ga.faq.questionClickEvent(label)
      return {
        ...gaEvent,
        category: gaEvent.category.replaceValues({ page: props.pageName })
      }
    }

    const gaTrigger = value => {
      return typeof value === 'number' ? $dga.event(accordionEvents(value)) : {}
    }

    return {
      t$,
      accordionEvents,
      buttonEvent,
      FAQItems,
      gaTrigger
    }
  }
}
</script>
