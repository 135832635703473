import { serverError } from '@/locales/pt-br/errors'

export default {
  title: 'Zonas DNS',
  subtitle: {
    welcome: 'Nossas boas-vindas, <strong>{name}</strong>',
    description: 'Aqui você pode configurar os serviços ligados aos seus dominios.',
    adminDescription: 'Aqui você selecionar um cliente para personificar.'
  },
  zones: {
    title: 'Lista de Domínios',
    filter: {
      label: 'Filtre a listagem de seus domínios na locaweb',
      domain: {
        label: 'Domínio',
        placeholder: 'Pesquisar domínio'
      },
      user: {
        label: 'Cliente',
        placeholder: 'Busque por um cliente'
      }
    }
  },
  table: {
    header: {
      zone: 'Domíno',
      actions: 'Ações'
    },
    emptyText: 'Nenhum domínio para ser exibido',
    actions: {
      create: 'Administrar',
      more: 'Mais Opções'
    }
  },
  result: {
    error: serverError,
    empty: {
      title: 'Nenhum domínio encontrado',
      description: 'Não encontramos nenhum domínio para realizar a configuração de DNS.',
      adminDescription: 'A busca por “{search}” não retornou nenhum resultado. Tente novamente com outro parâmetro de busca'
    }
  }
}
