import Chance from 'chance'

const chance = new Chance()
export default (server) => {
  for (let i = 1; i <= 1000; i++) {
    const type = chance.pickone(['A', 'AAAA', 'CNAME', 'MX', 'NS', 'TXT', 'SOA', 'SRV', 'PTR'])

    const name = chance.word({ length: 5 })
    server.create('record', {
      id: `${type}${name}`,
      type,
      content: [
        chance.word({ length: 1 })
      ],
      name: chance.word({ length: 5 }),
      ...['MX', 'SRV'].includes(type) & { priority: chance.integer({ min: 1, max: 100 }) },
      zoneId: chance.integer({ min: 1, max: 5 })
    })
  }
}
