import { ref } from 'vue'
import useHandleSave from './handleSave'
import useHandleDelete from './handleDelete'

export function useRecordAPI (t$, record, domain, $v, isEdit, login) {
  const loading = ref(false)
  const serverErrors = ref({})

  const handleSaveRecord = useHandleSave({
    t$,
    record,
    serverErrors,
    loading,
    isEdit,
    $v,
    domain,
    login
  })

  const handleDeleteRecord = useHandleDelete({ t$, record, loading, domain, login })
  return [loading, handleSaveRecord, handleDeleteRecord]
}
