<template>
  <div class="d-flex flex-column">
    <PropagationModal v-if="openPropagationModal" :authority="haveAuthority"/>
    <DeleteModal v-if="selectedRecord" @cancel="clearRecord" :loading="loadingDelete"
     @delete="handleDeleteRecord" :record="selectedRecord"/>
    <LoadingArea :displayContent="!loading">
      <template #loading>
        <SkeletonLoader type="list-item-two-line"/>
        <Row class="mt-4">
          <Col v-for="i in 3" :key="i" cols="12" sm="4">
          <SkeletonLoader type="list-item-three-line" />
          </Col>
        </Row>
        <SkeletonLoader class="mt-4"  type="table-tbody" elevation="1"/>
      </template>
      <Heading v-bind="headerProps" :divider="false">
        <template #action>
          <FAQDrawer pageName="Zona DNS"/>
        </template>
      </Heading>
      <WhoIs v-if="data" class="mb-5" :zone="data"/>
      <Card outlined contentPaddingLess v-if="hasRecords">
        <template #header>
          <div class="d-flex justify-space-between">
            <strong class="text-h3" v-text="t$.records.title"/>
            <Button  :ga="ga.zone.createRecord" v-bind="actionButtonProps"/>
          </div>
        </template>
        <DsTable style="table-layout:fixed" :headers="tableHeader" :items="data.records" :options.sync="pagination" @update:page="execute"
        @update:items-per-page="execute" :itemsPerPageOptions="[15,35, 50, 100]" :serverItemsLength="pagination.total"
        @update:options="serverSortItems">
          <template #item.content="{item}">
            <span v-if="!item.content.push" v-text="item.content"/>
            <template  v-else>
              <span class="d-block my-1" v-for="content in item.content" :key="content" v-text="content"/>
            </template>
          </template>
          <template v-slot:actions="{item}">
            <Button @click="onEdit(item)" icon="$objects-pencil-filled" small icon-size="dense"
            :title="blockEntry(item) ? t$.table.actions.blocked : t$.table.actions.edit" :ga="gaTrigger(item)" :disabled="blockEntry(item)"/>
            <Button @click="setRecord(item)" icon="$objects-trash-filled" small icon-size="dense"
            :title="blockEntry(item) ? t$.table.actions.blocked : t$.table.actions.delete" :ga="gaTrigger(item, 'remove')" :disabled="blockEntry(item)"/>
          </template>
        </DsTable>
      </Card>
      <template v-else>
        <PageResult :text-results="t$.result" :error="error" :actionRoute="actionRouter"
                    :errorStatusCode="statusCode" :values="{domain}"/>
      </template>
    </LoadingArea>
  </div>
</template>

<script>
import {
  Button,
  Card,
  Heading,
  LoadingArea,
  SkeletonLoader,
  Table,
  useAPI,
  useLocale,
  useRenamePath,
  useStorage,
  useVueRouter,
  Row,
  Col
} from '@locaweb/design-system-vue'
import { find } from '@/views/zone/api/zone.api'
import { computed, onBeforeMount, ref, watch } from 'vue'
import PageResult from '@/components/PageResult'
import { ZonesRoutesPath } from '@/views/zone/router'
import { RecordsRoutesPath } from '@/views/record/router'
import { useValidateDomain } from '@/functions/useValidateDomain'
import DeleteModal from '@/views/zone/detail/components/DeleteModal'
import PropagationModal from '@/views/zone/detail/components/PropagationModal'
import { useSelectItem } from '@/functions/useSelectItem'
import { useRecordAPI } from '@/views/record/form/functions/useRecordAPI'
import isEqual from 'lodash/isEqual'
import FAQDrawer from '@/views/shared/FAQDrawer'
import WhoIs from './components/WhoIs'
import ga from '@ga'
import { authStore } from '@/store/auth'
/**
 *  Componente de listagem de zonas
 */
export default {
  name: 'ZoneDetail',
  components: {
    WhoIs,
    FAQDrawer,
    PageResult,
    Heading,
    LoadingArea,
    SkeletonLoader,
    Card,
    Button,
    DsTable: Table,
    DeleteModal,
    PropagationModal,
    Row,
    Col
  },
  props: {
    domain: {
      type: String
    }
  },
  setup (props, { emit }) {
    const auth = authStore()
    const t$ = useLocale('records.list')
    const [login] = useStorage({ key: 'impersonate_login', initialValue: null, type: 'sessionStorage' })
    const { $router } = useVueRouter()
    const { addPathName } = useRenamePath()
    const [selectedRecord, setRecord, clearRecord] = useSelectItem()
    const openPropagationModal = localStorage.getItem('open modal for ' + props?.domain)

    useValidateDomain(props.domain)
    onBeforeMount(async _ => {
      await execute()
      addPathName({ path: `/${props.domain}`, name: props.domain })
      localStorage.setItem('domain', props?.domain)
      localStorage.setItem('authority', data?.value?.authority)
    })

    const pagination = ref({
      page: 1,
      itemsPerPage: 15,
      total: 0
    })

    const sortBy = ref(null)

    const { data, execute, loading, error, statusCode, responseHeaders } = useAPI({
      request: find,
      param: {
        param: props.domain,
        pagination,
        sortBy,
        ...auth.isAdmin && { login }
      },
      key: 'zone'
    })

    watch(() => data.value, () => {
      if (data.value) {
        pagination.value.total = parseInt(responseHeaders?.value['total-count']) || 0
        emit('finish', { data: data.value, headers: responseHeaders.value })
      }
    })

    function serverSortItems (options) {
      if (!options.sortBy.isEmpty()) {
        sortBy.value = {
          ...options.sortBy.reduce((acc, it, index) => ({ [it]: options.sortDesc[index] ? 'desc' : 'asc' }), {})
        }
      } else {
        sortBy.value = null
      }
    }

    watch(() => sortBy.value, (nextValue, oldValue) => {
      if ((nextValue && !isEqual(nextValue, oldValue)) || nextValue === null) {
        execute()
      }
    })

    const hasRecords = computed(_ => data.value && data.value.records && !data.value.records.isEmpty())

    const headerProps = computed(() => ({
      title: props.domain,
      subtitle: t$.subtitle
    }))

    const actionButtonProps = {
      color: 'primary',
      label: t$.createAction,
      to: RecordsRoutesPath.ADICIONAR.path.replace(':domain', props.domain)
    }

    const tableHeader = [
      {
        text: t$.table.header.name,
        align: 'start',
        value: 'name'
      },
      {
        text: t$.table.header.type,
        align: 'start',
        value: 'type',
        width: 100
      },
      {
        text: t$.table.header.content,
        align: 'start',
        value: 'content',
        sortable: false
      },
      { text: t$.table.header.actions, align: 'end', value: 'actions', sortable: false, width: 100 }
    ]

    const onEdit = (item) => {
      const [, setValue] = useStorage({ key: 'record', initialValue: null, type: 'sessionStorage' })
      setValue(item)
      $router.push(RecordsRoutesPath.EDITAR.path.replace(':domain', props.domain))
    }

    const actionRouter = computed(_ => error?.value
      ? ZonesRoutesPath.LISTAR
      : actionButtonProps.to
    )

    const [loadingDelete,, deleteRequest] = useRecordAPI(t$, selectedRecord, props.domain, false, false, login)

    const haveAuthority = localStorage.getItem('authority') === 'true'

    function handleDeleteRecord () {
      deleteRequest(execute)
      clearRecord()
    }

    function isNSRecord (item) {
      return item.type === 'NS'
    }

    function gaTrigger (item, type = 'edit') {
      return {
        ...ga.record[type],
        category: ga.record[type].category.replaceValues({ type: item.type })
      }
    }

    function blockEntry (item) {
      return ['NS', 'SOA'].includes(item.type)
    }

    return {
      t$,
      headerProps,
      data,
      loading,
      error,
      tableHeader,
      hasRecords,
      statusCode,
      actionButtonProps,
      actionRouter,
      selectedRecord,
      openPropagationModal,
      clearRecord,
      loadingDelete,
      haveAuthority,
      handleDeleteRecord,
      isNSRecord,
      execute,
      pagination,
      serverSortItems,
      ga,
      onEdit,
      setRecord,
      gaTrigger,
      blockEntry
    }
  }
}
</script>
