export default {
  register: {
    true: {
      title: 'Domínio registrado',
      description: 'Tudo certo. O domínio foi registrado com sucesso.',
      action: 'Acessar painel'
    },
    false: {
      title: 'Domínio não registrado',
      description: 'Você ainda não efetuou o registro do domínio. Que tal começar agora?',
      action: 'Registrar domínio'
    }
  },
  servers: {
    true: {
      title: 'Servidor configurado',
      description: 'Seus servidores DNS foram configurados com sucesso.',
      action: 'Alterar Configurações'
    },
    false: {
      title: 'Nenhum servidor configurado',
      description: 'Seus servidores DNS ainda não foram configurados.'
    }
  },
  authority: {
    true: {
      title: 'Zona de DNS com autoridade',
      description: 'A Zona de DNS da Locaweb possui autoridade neste domínio. Para garantir seu funcionamento, a alteração de entradas NS e SOA não é permitida.'
    },
    false: {
      title: 'Zona de DNS sem autoridade',
      description: 'A Zona de DNS da Locaweb não possui autoridade neste domínio.'
    }
  }
}
