import DomainList from '@/views/zone/list/ZoneList'
import ZoneDetail from '@/views/zone/detail/ZoneDetail'

const prefix = '/'

export const ZonesRoutesPath = Object.freeze({
  LISTAR: {
    path: prefix,
    title: 'zones.title',
    name: 'domainList',
    component: DomainList
  },
  DETALHAR: {
    path: `${prefix}:domain`,
    title: 'zones.title',
    name: 'domainDetail',
    component: ZoneDetail,
    props: true
  }
})

export default Object.values(ZonesRoutesPath)
